<template lang="pug">
.template-chooser-template-box(v-show="!template.hidden")
  .template-box-wrapper.ssr
    TemplateFrame.custom-theme-slider-template.cursor-pointer(
      allowSsr
      :dimensions="previewDimensions"
      :template="template"
      :themeKit="themeKit"
      @contentLoaded="onContentLoaded"
      @inited="$emit('inited')"
      @observable="$emit('observable', $event)"
      @click="showTemplatePreview()"
      @hide="$emit('hide')"
      @show="$emit('show')"
      :baseThemeKitName="baseThemeKitName"
    )
  .template-chooser-content
    .template-chooser-title
      span(@click="showTemplatePreview()") {{ title }}
</template>

<script>
  import previewParentMixin from '@/mixins/previewParent';
  import ssrMixin from '@/mixins/ssr';
  import { mapGetters } from 'vuex';

  export default {
    components: {
      TemplateFrame: () => import('@/components/Template/TemplateFrame.vue'),
    },
    mixins: [previewParentMixin, ssrMixin],
    props: {
      title: { type: String, default: null },
      template: { type: Object, required: true },
      themeKit: { type: Object, default: () => null },
      dimensions: { type: Object, default: () => null },
      heapEvent: { type: String, default: () => null },
      baseThemeKitName: { type: String, default: null },
    },
    data: () => ({
      firstRender: true,
      ssrBoxSelector: '.template-chooser-template-box .template-thumbnail-box',
    }),
    computed: {
      ...mapGetters(['getLocale']),
      previewDimensions() {
        return this.dimensions || this.boxDimensions;
      },
    },
    watch: {
      firstRender() {
        setTimeout(() => {
          this.$nextTick(() => {
            this.updateDimensions();
          });
        }, 300);
      },
    },
    created() {
      this.firstRender = false;
    },
    mounted() {
      this.$nextTick(() => {
        this.updateDimensions();
      });
    },
    methods: {
      showTemplatePreview() {
        window.history.pushState(undefined, undefined, '#preview');
        const heap = this.heapEvent ? { heapTracking: { name: this.heapEvent } } : {};
        this.$modal.show('template-preview', {
          templateId: this.template._id,
          themeKit: this.themeKit,
          ...heap,
        });
      },
    },
  };
</script>

<style lang="sass">
  @import '@/sass/variables/_colors.sass'
  .template-chooser-template-box
    .body-text
      line-break: anywhere
    &:hover
      .template-chooser-title
        color: $om-orange-500
  .template-chooser
      &-content
        padding: 14px 0
      &-title
        cursor: pointer
        font-size: 0.9rem
        transition: .3s all
</style>
